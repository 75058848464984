<template>
    <div class="container">
        <div>
            <header class="header d-flex flex-column flex-md-row container-fluid">
                <div class="me col-sm-12 col-md-4 col-lg-3">
                    <div class="photo" :class="{loading: this.loading}">
                        <img ref="photo" :src="photo" height="253" width="199">
                    </div>
                    <div class="full-name">
                        <h2>{{ $t('me.fullName') }} <span>{{ $t('me.position') }}</span></h2>
                    </div>
                </div>
                <div class="about col-sm-12 col-md-8 col-lg-9">
                    <h2>{{ $t('cv.about') }}</h2>
                    <p :class="{loading: this.loading}">{{ about }}</p>
                </div>
            </header>

            <section class="content d-flex flex-column flex-md-row flex-wrap">
                <article class="skills col-sm-12 col-md-4 col-lg-3">
                    <h3>{{ $t('cv.skills') }}</h3>
                    <div :class="{loading: this.loading}">
                        <ul>
                            <li v-for="skill in skills" :key="skill.value">
                                {{ skill.value }}
                            </li>
                        </ul>

                        <ul :class="{loading: this.loading}">
                            <li v-for="skill in secondarySkills" :key="skill.value">
                                {{ skill.value }}
                            </li>
                        </ul>
                    </div>
                </article>

                <article class="experience col-sm-12 col-md-8 col-lg-9">
                    <h3>{{ $t('cv.experience') }}</h3>
                    <p class="content-experience-short" :class="{loading: this.loading}">{{ employmentDescription }}</p>
                    <ul :class="{loading: this.loading}">
                        <li v-for="experience in employers" :key="experience.value.company">
                            <ul>
                                <li>{{ experience.value.position }}</li>
                                <li>
                                    <a v-if="experience.value.companyUrl" :href="experience.value.companyUrl" target="_blank">{{ experience.value.company }}</a>
                                    <span v-else>{{ experience.value.company }}</span>
                                </li>
                                <li>{{ formatDate(experience.value.dateFrom) }} – {{ experience.value.dateTo ? formatDate(experience.value.dateTo) : $t('cv.currently') }}</li>
                                <li>{{ experience.value.responsibilities }}</li>
                            </ul>
                        </li>
                    </ul>
                </article>

                <article class="tools col-sm-12 col-md-4 col-lg-3">
                    <h3>{{ $t('cv.tools') }}</h3>
                    <ul :class="{loading: this.loading}">
                        <li v-for="tool in tools" :key="tool.value">
                            {{ tool.value }}
                        </li>
                    </ul>
                </article>

                <article class="education col-sm-12 col-md-8 col-lg-9">
                    <h3>{{ $t('cv.education') }}</h3>
                    <ul :class="{loading: this.loading}">
                        <li v-for="education in this.education" :key="education.value.area">
                            <ul>
                                <li>{{ education.value.instutution }}</li>
                                <li>{{ education.value.area }}</li>
                                <li>{{ formatDate(education.value.dateFrom) }} – {{ education.value.dateTo ? formatDate(education.value.dateTo) : $t('cv.currently') }}</li>
                            </ul>
                        </li>
                    </ul>
                </article>

                <article class="hobby col-sm-12 col-md-4 col-lg-3">
                    <h3>{{ $t('cv.hobby') }}</h3>
                    <p v-html="this.hobby" :class="{loading: this.loading}"></p>
                </article>

                <article class="contact col-sm-12 col-md-8 col-lg-9">
                    <h3>{{ $t('cv.contact') }}</h3>
                    <ul class="d-flex flex-column flex-lg-row flex-wrap" :class="{loading: this.loading}">
                        <li class="d-flex flex-row" v-for="contact in this.contact" :key="contact.value.name">
                            <img :src="contact.value.icon.path" alt="">
                            <a :href="contact.value.url" target="_blank">{{ contact.value.name }}</a>
                        </li>
                    </ul>
                </article>

            </section>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import api from "../api";
    import eventBus from '../eventBus';

    export default {
        name: "Cv",
        data() {
            return {
                awaitingResponse: true,
                loading: true,
                photo: '',
                about: '',
                skills: [],
                secondarySkills: [],
                employmentDescription: '',
                employers: [],
                tools: [],
                education: [],
                hobby: '',
                contact: [],
            }
        },
        created() {
            this.loadData();
        },
        mounted() {
            eventBus.$on('language', () => {
                this.loading = true;
                this.awaitingResponse = true;
                this.loadData();
            });
        },
        methods: {
            async loadData() {
                const response = await axios.get( api.cmsPath + '/api/singletons/get/cv?lang=' + this.$i18n.locale);
                if( !response.data) return;
                const data = response.data;

                this.photo = api.cmsPath + data.photo.path;
                this.about = data.about;
                this.skills = data.skills;
                this.secondarySkills = data.skillsSecondary;
                this.tools = data.tools;
                this.education = data.education;
                this.employmentDescription = data.employmentDescription;
                this.employers = data.employers;
                this.hobby = data.hobby;

                data.contact.forEach((entry) => {
                    entry.value.icon.path = api.cmsPath + entry.value.icon.path;
                });
                this.contact = data.contact;

                const loader = setInterval(() => {
                    if(!this.$refs.photo || this.$refs.photo.complete === false) return;
                    this.loading = false;
                    clearInterval(loader);
                }, 100);
            },
            formatDate(date) {
                const formatted = new Date(date).toLocaleString('pl-pl',{month:'long', year:'numeric'});
                return formatted.charAt(0).toUpperCase() + formatted.substr(1);
            }
        }
    };
</script>

<style lang="scss" scoped>
    a,
    p ::v-deep a {
        color: $darkYellow;
    }

    .header {
        margin-top: 2rem;
        padding: 0 45px 15px 45px;

        @include media-breakpoint-up(md){
            padding: 0 15px 50px 15px;
        }

        .me {
            padding: 0;

            .photo {
                height: 253px;
                width: 199px;
                z-index: -1;
                box-shadow: -20px 65px $yellow;

                @include media-breakpoint-up(xs) {
                    box-shadow: -30px 75px $yellow;
                }

                @include media-breakpoint-up(md) {
                    box-shadow: -30px 100px $yellow;
                }

                img {
                    object-fit: cover;
                }
            }

            .full-name {
                width: calc(100vw - 60px);
                font-size: 10px;

                @include media-breakpoint-up(xs) {
                    font-size: 12px;
                }

                @include media-breakpoint-up(md) {
                    width: 50vw;
                    font-size: 16px;
                }

                h2,
                h2 span {
                    text-transform: none;
                }

                h2 {
                    letter-spacing: 0;
                    line-height: 0.85em;
                    margin-top: 10px;
                    font-size: 3em;
                }

                h2 span {
                    font-size: 0.68em;
                    display: block;
                }
            }
        }

        .about {
            padding: 15px 0 0;

            @include media-breakpoint-up(md) {
                padding: 20px 15px 0 15px;
            }

            @include media-breakpoint-up(xl) {
                padding-top: 2.5rem;
            }

            p {
                min-height: 180px;
                font-size: 1rem;
                line-height: 1.5;

                @include media-breakpoint-up(md) {
                    font-size: 0.95rem;
                    line-height: 1.2;
                }

                @include media-breakpoint-up(lg) {
                    min-height: 140px;
                    font-size: 1rem;
                    line-height: 1.4;
                }

                @include media-breakpoint-up(xl) {
                    min-height: 110px;
                    line-height: 1.5;
                }
            }
        }
    }

    .content {
        article {
            margin-bottom: 2em;
            transition: 0.3s ease;

            &.experience {
                & > p {
                    min-height: 3rem;
                }
                & > ul {
                    min-height: 18rem;
                }
            }

            &.skills > div {
                min-height: 22rem;
            }

            &.hobby {
                order: 2;

                & > p {
                    min-height: 6rem;
                }
            }

            &.education {
                order: 0;

                & > ul {
                    min-height: 6rem;
                }
            }

            &.tools {
                order: 1;

                & > ul {
                    min-height: 6rem;
                }
            }

            @include media-breakpoint-up(md) {
                &.education,
                &.tools {
                    order: unset;
                }
            }

            &.contact {
                order: 3;

                ul {
                    padding: 0;
                    list-style: none;
                    min-height: 5rem;

                    &.loading {
                        min-height: 6rem;
                    }

                    @include media-breakpoint-up(lg) {
                        flex-direction: row;
                    }
                }

                li {
                    padding: 0.25em 0;
                    align-items: center;

                    @include media-breakpoint-up(lg) {
                        width: 50%;
                    }

                    img {
                        height: 1.25em;
                        width: 1.25em;
                        margin-right: 0.75em;
                    }
                }
            }

            &.experience,
            &.education {
                ul {
                    padding-left: 0;
                    list-style: none;
                }

                > ul > li {
                    margin-left: 20px;
                    border-left: 2px solid $yellow;
                    padding: 0.5em 0 0.5em 15px;

                    &::before {
                        content: "";
                        height: 10px;
                        width: 10px;
                        background: $yellow;
                        position: absolute;
                        margin: 17px 0 0 -21px;
                    }
                }

                ul ul {
                    display: flex;
                    flex-wrap: wrap;

                    li {
                        display: flex;

                        &:first-child {
                            font-weight: 400;
                            font-size: 1.3em;

                            @include media-breakpoint-up(xl) {
                                &::after {
                                    content: "-";
                                    font-weight: 700;
                                    padding: 0 0.7em;
                                }
                            }
                        }

                        &:nth-child(2) {
                            font-style: italic;
                            font-size: 0.9em;
                            width: 100%;
                            margin: -0.2em 0 0.35em;

                            @include media-breakpoint-up(xl) {
                                margin: 0.4em 0 0 0;
                                width: auto;
                            }
                        }

                        &:nth-child(3) {
                            flex-basis: 100%;
                            line-height: unset;
                            margin-top: -5px;
                            font-size: 0.9em;
                        }

                        &:nth-child(4) {
                            line-height: 1.2em;
                            font-weight: 500;
                            padding: 0.5em 0;
                        }
                    }
                }
            }

            h3 {
                letter-spacing: 2px;
                position: relative;
                display: inline;

                &::before {
                    content: "";
                    width: 100%;
                    margin-top: 0.6em;
                    height: 0.4em;
                    position: absolute;
                    background: $yellow;
                    left: 0;
                    z-index: -1;
                }

                &::after {
                    content: "";
                    width: 100%;
                    height: 10px;
                    display: block;
                }
            }
        }
    }

    @keyframes loading {
        100% {
            transform: translateX(100%);
        }
    }

    .loading {
        position: relative;
        background-color: $light;
        overflow: hidden;
        font-size: 0 !important;

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            background: linear-gradient(90deg, transparent,
                    rgba(255, 255, 255, 0.5), transparent);
            animation: loading 1s infinite;
            z-index: 999999;
            transition: 0.3s ease;
        }

        *,
        *::before,
        *::after {
            display: none !important;
        }
    }

    .photo.loading::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: $light;
        z-index: 99999;
    }
</style>
